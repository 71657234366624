import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: firebase.User;

  constructor(
    public afAuth: AngularFireAuth
  ) { 
    afAuth.authState.subscribe(user => {
      //console.log('assigned logged in user: ',user);
      this.user = user;
    })
  }

  loginUser(email: string, password: string): Promise<firebase.auth.UserCredential>{
    //console.log('Sign in with email');
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    //return firebase.auth().signInAndRetrieveDataWithEmailAndPassword(email, password);
  }

  logoutUser(): Promise<void> {
    return firebase.auth().signOut();
  }

  resetPasswordByEmail(email: string){
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
