import { Component, ViewChildren, QueryList } from '@angular/core';

import { Platform, Events, MenuController, IonRouterOutlet, ActionSheetController, PopoverController, ModalController, ToastController } from '@ionic/angular';

import * as firebase from 'firebase/app';
import { environment } from '../environments/environment';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Router } from '@angular/router';

import { Toast } from '@ionic-native/toast/ngx';
import { ProfileService } from './services/user/profile.service';
import { FcmService } from './services/fcm/fcm.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { SwUpdate } from '@angular/service-worker';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  subscription;

  userProfile: any;
  userAccount: any;

  installedApps: any;

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  constructor(
    private platform: Platform,
    private events: Events,
    private menu: MenuController,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    private router: Router,
    private toast: Toast,
    private profileServices: ProfileService,
    private fcm: FcmService,
    public toastController: ToastController,
    private afMessaging: AngularFireMessaging,
    private swUpdate: SwUpdate
  ) {
    //console.log('App componen constructor')
    this.initializeApp();
    this.backButtonEvent();

    this.profileServices.getUserProfile().then(data => {
      //console.log('loaded profile: ',data);
      this.userProfile = data;
      this.userAccount = this.profileServices.getUserAccount();
      this.platform.ready().then(() => {
        this.notificationSetup();
      });
      this.loadApps();
    });
    
    
  }

  ngOnInit() {
    if(this.swUpdate.isEnabled){
      this.swUpdate.available.subscribe(() => {
        if(confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      });
    }
  }

  async showNewVersionToast(){
    const toast = await this.toastController.create({
      
      message: 'New Update Available',
      position: 'bottom'
    })
  }

  private async presentToast(message){
    const toast = await this.toastController.create({
      message,
      duration: 3000
    });
    toast.present();
  }
  
  private notificationSetup() {
    //console.log('notificationSetup start');
    this.fcm.getToken();
    this.fcm.onNotifications().subscribe((msg) => {
      if(this.platform.is('ios')){
        this.presentToast(msg.aps.alert);
      } else {
        this.presentToast(msg.body);
      }
    })
  }

  initializeApp() {
    //firebase.initializeApp(environment.firebase);
    //SplashScreen.hide().catch(error => {
    //  console.error(error);
    //});

    //StatusBar.hide().catch(error => {
    //  console.error(error);
    //});
    //this.loadApps();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
      if(this.platform.is('android')) {
        //console.log('Platform android');
      }
    });
    
    
  }

  loadApps(){
    if(!this.userAccount || !this.userAccount.installedApps){
      this.installedApps = [];
      //console.log('account does not have apps')
      return;
    }
    this.installedApps = [];
    const appNames = Object.keys(this.userAccount.installedApps);
    if(appNames){
      for(let appName of appNames){
        const installedApp = this.userAccount.installedApps[appName];
        installedApp.appName = appName;
        if(installedApp.allowedUsers[this.userProfile.uid] && installedApp.allowedUsers[this.userProfile.uid].view){
          this.installedApps.push(installedApp);
        }
        
      }
    }

  }

  backButtonEvent() {
    //console.log('backbutton event start');
    
    this.platform.backButton.subscribe(() => {
      //console.log('subscribed to back button');
      

        navigator['app'].exitApp();
        
        return;

      });
}

  ionViewWillLeave(){
    this.subscription.unsubscribe();
  }

  mainMenuClose():void {
    //console.log('Publishing mainMenu:closed')
    this.events.publish('mainMenu:closed');
  }

  mainMenuClick():void {
    this.menu.close('mainMenu');
  }
}
