import { Injectable } from '@angular/core';
import { ProfileService } from '../user/profile.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private profileService: ProfileService,
    private afs: AngularFirestore
  ) { }

  getAllAccounts():AngularFirestoreCollection<{}> {
    return this.afs.collection(`accounts`);
  }

  getAccountDocument(aid: string): Promise<AngularFirestoreDocument<{}>>{
    return new Promise(resolve => {
      resolve(this.afs.doc(`accounts/${aid}`));
    })
  }
}
