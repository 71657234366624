import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Firebase } from '@ionic-native/firebase/ngx';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AuthService } from './services/user/auth.service';
import { ProfileService } from './services/user/profile.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { FcmService } from './services/fcm/fcm.service';
import { ViewGuard } from './services/user/view.guard';
import { AccountService } from './services/accounts/account.service';
import { MasteradminGuard } from './services/accounts/masteradmin.guard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CarsInventoryService } from './services/cars-inventory/cars-inventory.service';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { DropZoneDirectiveModule } from './directives/drop-zone.module';
import { AppAccessGuard } from './services/accounts/app-access.guard';
import { Toast } from '@ionic-native/toast/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFireStorageModule,
    
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    SharedModule,
    ReactiveFormsModule
  ],
  providers: [
    Firebase,
    StatusBar,
    SplashScreen,
    AuthService,
    ProfileService,
    AccountService,
    CarsInventoryService,
    FcmService,
    ViewGuard,
    MasteradminGuard,
    AppAccessGuard,
    Toast,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
