import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { AppComponent } from './app.component';
import { ViewGuard } from './services/user/view.guard';
import { MasteradminGuard } from './services/accounts/masteradmin.guard';
import { AppAccessGuard } from './services/accounts/app-access.guard';

const routes: Routes = [
  { path: '',  canActivate: [AuthGuard],
  children: [
  { path: '', loadChildren: './home/home.module#HomePageModule' },
  /* { path: 'home', loadChildren: './home/home.module#HomePageModule' }, */
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'messages', loadChildren: './pages/messages/messages.module#MessagesPageModule' },
  { path: 'accounts', loadChildren: './pages/accounts/accounts.module#AccountsPageModule', canActivate: [MasteradminGuard] },
  { path: 'projects-center', loadChildren: './pages/projects-center/projects-center.module#ProjectsCenterPageModule', canActivate: [MasteradminGuard]},
  { path: 'service-orders', loadChildren: './pages/service-orders/service-orders.module#ServiceOrdersPageModule', canActivate: [MasteradminGuard]},
  { path: 'cars-inventory', loadChildren: './pages/cars-inventory/cars-inventory.module#CarsInventoryPageModule', canActivate: [AppAccessGuard], data: { currentApp: 'CarsInventory'} },
  ]

  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'conversations', loadChildren: './pages/messages/conversations/conversations.module#ConversationsPageModule' },
  { path: 'conversation', loadChildren: './pages/messages/conversation/conversation.module#ConversationPageModule' },
  { path: 'start-new', loadChildren: './pages/messages/start-new/start-new.module#StartNewPageModule' },
 
  //{ path: 'edit-car', loadChildren: './pages/cars-inventory/edit-car/edit-car.module#EditCarPageModule' },
  //{ path: 'cars-tv-listing', loadChildren: './pages/cars-inventory/cars-tv-listing/cars-tv-listing.module#CarsTvListingPageModule' },
  //{ path: 'projects-center', loadChildren: './pages/projects-center/projects-center.module#ProjectsCenterPageModule' },
  //{ path: 'dashboard', loadChildren: './pages/projects-center/dashboard/dashboard.module#DashboardPageModule' },
  

  
  //{ path: 'accounting-center', loadChildren: './pages/accounting-center/accounting-center.module#AccountingCenterPageModule' },
  //{ path: 'billing-center', loadChildren: './pages/billing-center/billing-center.module#BillingCenterPageModule' },
  //{ path: 'list-projects', loadChildren: './pages/projects-center/list-projects/list-projects.module#ListProjectsPageModule' },
  //{ path: 'create-project', loadChildren: './pages/projects-center/create-project/create-project.module#CreateProjectPageModule' },
  //{ path: 'details-project', loadChildren: './pages/projects-center/details-project/details-project.module#DetailsProjectPageModule' },
  { path: 'assets-tracking', loadChildren: './pages/assets-tracking/assets-tracking.module#AssetsTrackingPageModule', canActivate: [MasteradminGuard, AppAccessGuard], data: {currentApp: 'assetsTracking'} },
  { path: 'cars-inventory-web-plugin', loadChildren: './pages/cars-inventory/cars-inventory-web-plugin/cars-inventory-web-plugin.module#CarsInventoryWebPluginPageModule' },

  //{ path: 'service-orders-create', loadChildren: './pages/service-orders/service-orders-create/service-orders-create.module#ServiceOrdersCreatePageModule' },
  //{ path: 'service-orders-list', loadChildren: './pages/service-orders/service-orders-list/service-orders-list.module#ServiceOrdersListPageModule' },
  //{ path: 'service-orders-details', loadChildren: './pages/service-orders/service-orders-details/service-orders-details.module#ServiceOrdersDetailsPageModule' },

  //{ path: 'assets-list', loadChildren: './pages/assets-tracking/assets-list/assets-list.module#AssetsListPageModule' },
  //{ path: 'assets-details', loadChildren: './pages/assets-tracking/assets-details/assets-details.module#AssetsDetailsPageModule' },


  
  //{ path: 'add-new-car', loadChildren: './pages/cars-inventory/add-new-car/add-new-car.module#AddNewCarPageModule' },
  //{ path: 'list-cars', loadChildren: './pages/cars-inventory/list-cars/list-cars.module#ListCarsPageModule' },
  //{ path: 'service-orders', loadChildren: './pages/service-orders/service-orders.module#ServiceOrdersPageModule' },
  //{ path: 'service-orders-list', loadChildren: './pages/serviceOrders/service-orders-list/service-orders-list.module#ServiceOrdersListPageModule' },
  //{ path: 'service-orders-new-order', loadChildren: './pages/serviceOrders/service-orders-new-order/service-orders-new-order.module#ServiceOrdersNewOrderPageModule' },
  
  //{ path: 'accounts-list', loadChildren: './pages/accounts/accounts-list/accounts-list.module#AccountsListPageModule' },
  
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
