import { Injectable } from '@angular/core';
import { ProfileService } from '../user/profile.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CarsInventoryService {

  constructor(
    private profileService: ProfileService,
    private afs: AngularFirestore
  ) { }

  getAccountCarsInventoryCollection(aid: string):AngularFirestoreCollection<{}> {
    return this.afs.collection(`accounts/${aid}/cars_inventory_listing`, ref =>ref.orderBy('updatedAt', 'desc'));
  }

  getAccountCarsInventoryDocument(aid:string, carId: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(`accounts/${aid}/cars_inventory_listing/${carId}`))
    });
  }
}
