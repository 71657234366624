import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/user/profile.service';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  userProfile: any;

  constructor(
    private profileServices: ProfileService,
    private menu: MenuController,
    private router: Router
  ) { 

    this.profileServices.getUserProfile().then(data => {
      console.log('loaded profile: ',data);
      this.userProfile = data;
    })

  }

  ngOnInit() {}

  openProfile(){
    this.router.navigateByUrl('/profile');
  }

  enableMainMenu():void {
    //console.log('enable menu in messages');
    this.menu.enable(true, 'mainMenu');
    this.menu.open('mainMenu');
  }

}
