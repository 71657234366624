import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { ProfileService } from '../user/profile.service';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  userProfile: any;
  userAccount: any;

  constructor(
    private firebase: Firebase,
    private afs: AngularFirestore,
    private platform: Platform,
    private profileServices: ProfileService,
    private afMessaging: AngularFireMessaging
  ) { 
    this.profileServices.getUserProfile().then(data => {
      //console.log('loaded profile: ',data);
      this.userProfile = data;
      this.userAccount = this.profileServices.getUserAccount();
      
    });
  }


  public async getToken() {
    //console.log('getToken() start');
    //console.log('Platform: ',this.platform.is('cordova'));
    let token;
    if(this.platform.is('ios')) {
      token = await this.firebase.getToken();
      await this.firebase.grantPermission();
    }
    
    if(this.platform.is('android') || this.platform.is('cordova')){
      token = await this.firebase.getToken();
      //console.log('android token: ',token);
    }

    if(this.platform.is('pwa') || this.platform.is('desktop')){
      console.log('Got PWA.');
      this.afMessaging.requestToken.subscribe((t)=> {
        console.log('Permission grantend. Token:',t);
        token = t;
        this.saveToken(t);
      })
    } else {
      this.saveToken(token);
    }

    
  }

  private saveToken(token){
    if(!token) return;

    const devicesRef = this.afs.collection(`accounts/${this.userProfile.aid}/devices`);
    const data = {
      token,
      uid: this.userProfile.uid,
      aid: this.userAccount.aid
    }
    //console.log('Saving token data: ',data);
    //console.log(`saving to accounts/${this.userProfile.aid}/devices`);
    return devicesRef.doc(token).set(data);
    
  }

  onNotifications() {
    return this.firebase.onNotificationOpen();
  }
}
