import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { ProfileService } from '../user/profile.service';

@Injectable({
  providedIn: 'root'
})
export class MasteradminGuard implements CanActivate {
  constructor(private router: Router,
    private profileService: ProfileService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise((resolve, reject) => {
        console.log('AuthGuard current route: ',next);
        this.profileService.getUserProfile().then(data => {
          //console.log('loaded profile: ',data);
          const userProfile = data;
          const userAccount = this.profileService.getUserAccount();
          //console.log('user account:', userAccount);
          if(userAccount.aid === 'FbQmFT31BmsK9uetNgee'){
            resolve(true);
          } else {
            resolve(false);
          }
          
        });
  
        
      })
  }
}
