import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AngularFireAuth } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public userProfile: firebase.firestore.DocumentReference;
  public currentUser: firebase.User;
  public userProfileData: any;

  private user: any;
  private account: any;

  private fbSubscription: Subscription;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore
  ) {
    //console.log('profileService constructor start');
    afAuth.authState.subscribe(user => {
      //console.log('assigned logged in user: ',user);
      this.user = user;
    })
   }

   getUserProfile(): any {
     return new Promise((resolve, reject) => {
        if(this.fbSubscription){
          this.fbSubscription.unsubscribe();
        }

        this.afAuth.authState.subscribe((user) => {
          if(user){
            //this.user = user;
            this.afs.doc(`users/${user.uid}`).valueChanges().subscribe(user => {
              this.user = user;
              this.afs.doc(`accounts/${this.user.aid}`).valueChanges().subscribe((account) => {
                this.account = account;
                resolve(user);
              })
            })
            
          } else {
            resolve(null);
          }
        })

     });
      
   }

   getUserAccount() {
     return this.account;
   }

   getUserData(): any {
     if(this.currentUser){
       return this.currentUser
     } else {
       return null;
     }
   }

   getUserAid(): Promise<any> {
    if(this.userProfile) 
    return this.userProfile
     .get()
      .then( userProfileSnapshot => {
      return userProfileSnapshot.data().aid
      //this.birthDate = userProfileSnapshot.data().birthDate;
    }) 
    else return null;
   }

}
